@import "../../../common.scss";

.Header {
  padding: $lr-padding;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 110px;
  transition: all 500ms ease-in-out;
  @include headerBackground($sc-color);
  @include position(fixed, 0, 0, unset, 0);

  @media #{$break-smallMax} {
    height: 80px;
    padding: 1.6em;
  }

  @media #{$break-largeLandscape} {
    padding: 3em;
  }

  &__figure {
    @include showAnimation(20px);
    animation-delay: $time-delay + 0.2s;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }

    &__text {
      @include headerText();
      font-weight: lighter;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    transition: all $timing-transition cubic-bezier(0.165, 0.84, 0.44, 1);

    &__email {
      @include showAnimation(20px);
      position: relative;
      animation-delay: calc(#{$time-delay} + 1.4s);
      text-transform: uppercase;
      letter-spacing: 0.15px;
      font-size: 0.8em;
      margin: 40px auto;
      padding: 0 10px;
      color: $sc-color;

      &__a {
        text-transform: uppercase;
        margin: 0;
      }
    }

    &__plant {
      @include position(absolute, unset, -25px, 0, unset);
      opacity: 0.3;
      z-index: -1;
      width: 200px;

      @media #{$break-landscape} {
        top: 100px;
      }
    }

    @media #{$break-smallMax} {
      @include position(fixed, 0, 0, 0, 0);
      display: none;
      background: $pp-color;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      transform: translateY(-100%);
      animation: showMenu 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

      @keyframes showMenu {
        100% {
          transform: translateY(0);
        }
      }

      &.showMenu {
        display: flex;
      }
    }
  }

  &__responsive {
    @include showAnimation(20px);
    animation-delay: $time-delay + 0.2s;
    z-index: 1;
    display: none;
    font-size: 1em;
    color: inherit;
    padding: 5px;
    align-items: center;

    @media #{$break-smallMax} {
      display: flex;
    }

    &__lines {
      position: relative;
      height: 24px;
      width: 24px;
      margin-left: 5px;
      display: block;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        border-top: 1px solid;
        width: 70%;
        transition: all 200ms ease;
      }
      &::before {
        transform: translate(-50%, -4px);
      }
      &::after {
        transform: translate(-50%, 2px);
      }

      &.is-visible {
        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }
}
