@import "../../../common.scss";

.Dot {
  @include showAnimation(20px);
  position: relative;
  cursor: pointer;
  height: $dot-size;
  width: $dot-size;
  border-radius: 50%;
  border: 1px solid $brand-color_2;
  margin: 15px;
  transition: all $timing-transition ease-in-out;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: $dot-size;
    width: $dot-size;
    border-radius: 50%;
    background: $brand-color_2;
  }

  @keyframes pulse {
    100% {
      transform: translate(-50%, -50%) scale(6);
      opacity: 0;
    }
  }

  &.active {
    background: $brand-color_2 !important;
    transform: scale(1.4);
  }

  @for $i from 1 through 3 {
    &:nth-of-type(#{$i}) {
      animation-delay: #{$i * $time-delay};
    }
  }
}
