@import url("https://fonts.googleapis.com/css?family=Muli:400,600,700,900|Roboto+Mono|Ubuntu|Raleway:400,900&display=swap");
//Vars
$pp-color: #191919;
$sc-color: #f2f4f6;
$th-color: #cc5c00;
$brand-color_1: #11cebb;
$brand-color_2: #11a9bb;

$dot-size: 10px;
$max-footer: 350px;
$max-width: 1400px;
$lr-padding: 4em;
$responsive-padding: 2.5em;
// $header-height: 50px;
$header-spacing: 0.05em;

//Transitions
$timing-transition: 500ms;
$time-delay: 0.3s;

//Breakpoints
$break-smallMax: "only screen and (max-width : 767px)";
$break-smallMin: "only screen and (min-width : 768px)";
$break-largeHeight: "only screen and (max-width: 769px) and (min-height: 730px) ";
// $break-ipad: "only screen and (min-height: 768px) and (max-width: 767px)";
$break-landscape: "only screen and (max-width: 767px) and (max-height: 640px) and (orientation : landscape) ";
$break-largeLandscape: "only screen and (min-width: 767px) and (max-height: 768px) and (orientation : landscape) ";

//Mixins
@mixin headerText {
  font-size: 1em;
  text-transform: capitalize;
  letter-spacing: $header-spacing;
}

@mixin headerBackground($color) {
  background: linear-gradient(to top, rgba($color, 0), rgba($color, 0.95) 50%);
}

@mixin showAnimation($posY) {
  opacity: 0;
  transform: translateY($posY);
  animation: show 800ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@mixin position($type, $t, $r, $b, $l) {
  position: $type;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
}

@mixin patternBackground($url) {
  &::before {
    content: "";
    background: url($url) repeat;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    @media #{$break-smallMax} {
      opacity: 0.2;
    }
  }
}

@mixin intersectionStyles {
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(100px);
}

@mixin textShadow($color) {
  text-shadow: 1px 1px 2px $color, 2px 0 0 $color, -2px 0 0 $color,
    0 2px 0 $color, 0 -2px 0 $color, 1px 1px $color, -1px -1px 0 $color,
    1px -1px 0 $color, -1px 1px 0 $color;
}

//Keyframes
@keyframes show {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
