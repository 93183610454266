@import "../../../common.scss";

.Footer {
  position: relative;
  opacity: 0;
  background: white;
  max-height: $max-footer;
  animation: appear 500ms ease 1s forwards;
  // @include position(fixed, unset, 0, 0, 0);

  @keyframes appear {
    100% {
      opacity: 1;
    }
  }

  @media #{$break-smallMax} {
    // position: relative;
    max-height: none;
  }

  @media #{$break-largeLandscape} {
    max-height: none;
  }

  &__anchors {
    border-bottom: 1px solid #ededed;
    max-width: $max-width;
    margin: 0 auto;
    padding: 2em $lr-padding;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$break-smallMax} {
      width: 80%;
      padding-left: 0;
      padding-right: 0;

      flex-direction: column;
    }

    &__logo {
      padding: 10px;
    }

    &__nav {
      height: 100%;
      padding: 10px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__title {
        margin-bottom: 15px;
        font-weight: normal;
        color: $brand-color_2;
      }
    }
  }

  &__author {
    @include showAnimation(20px);
    animation-delay: calc(#{$time-delay} + 3.2s);
    max-width: $max-width;
    margin: 0 auto;
    padding: 2em $lr-padding;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media #{$break-smallMax} {
      font-size: 0.9em;
      padding: 15px $responsive-padding 15px $responsive-padding;
      flex-direction: column;

      small {
        margin: 3px 0;
      }
    }

    &__year span {
      margin-right: 10px;
    }
  }
}
