@import "../../../common.scss";

.Button {
  @include showAnimation(20px);
  position: relative;
  max-width: 200px;
  width: 100%;
  padding: 15px 10px;
  margin: 35px 0;
  font-size: 1.1em;
  font-weight: bold;
  color: $sc-color;
  background: linear-gradient(to left, $brand-color_1, $brand-color_2);
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 15px 40px rgba($brand-color_2, 0.2);
  transition: all 200ms ease-in;

  &:hover {
    box-shadow: 0 0 5px rgba($brand-color_2, 0.2);
  }

  &:active {
    transform: scale(0.99);
  }

  &--header {
    animation-delay: calc(#{$time-delay} + 1.3s);
    font-size: 0.8em;
    margin: 0;

    @media #{$break-smallMax} {
      animation-delay: calc(#{$time-delay} + 1.2s);
      width: 100px;
      margin: 0 0 60px 40px;
    }

    @media #{$break-landscape} {
      margin: 10px 40px;
    }
  }

  &--contact {
    animation-delay: calc(#{$time-delay} + 1.2s);
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: capitalize;

    &:hover > .emoji {
      transform: translateX(15px);
    }

    .emoji {
      font-size: 1.1em;
      font-weight: bold;
      transition: all 200ms linear;
    }

    @media #{$break-smallMax} {
      width: 250px;
      margin: 35px auto;

      .emoji {
        font-size: 1em;
      }
    }
  }
}
