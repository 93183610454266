@import "../../common.scss";
.Resume.pdf {
  max-width: none;
  margin: 0;
  .Resume__header {
    padding: 0;
    &__link {
      display: none;
    }
  }
  .Resume__description {
    grid-template-columns: 25% auto;
    &__photo {
      margin-left: 25px;
    }
  }
  .Resume__main {
    &__data{
      flex: 1 1 20%;
    }
  }

  .SumaryProfile,
  .TechnicalData {
    min-height: 400px;
    &__list {
      padding: 0 0 0 50px;
    }
    &__list__item{
      margin: 20px 0;

      &__description p{
        line-height: 27px;
      }
    }
  }
}

.Resume {
  max-width: $max-width - 100;
  margin: 30px auto;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 50px;

  @media #{$break-smallMax} {
    text-align: center;
    margin: 0 auto;
    padding: 15px;
  }

  h3 {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  &__header {
    position: relative;
    min-height: 200px;
    text-align: center;
    padding: 50px 0;

    &__name {
      font-family: "Raleway", sans-serif;
      font-size: 4.4em;
      font-weight: 900;
      margin-bottom: 10px;

      @media #{$break-smallMax} {
        font-size: 2.7em;
      }
    }

    &__title {
      margin-bottom: 10px;
      font-size: 1.8em;
    }

    &__link {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.8em;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scaleY(0);
        transform-origin: 0 bottom;
        transition: all 200ms ease;
        width: 100%;
        height: 100%;
        padding: 15px 10px;
        border-radius: 4px;
        background: rgba($brand-color_2, 0.1);
      }

      &:hover::before {
        transform: translate(-50%, -50%) scaleY(1);
      }

      .emoji {
        font-size: 1.6em;
        color: $brand_color-2;
      }
    }
  }

  &__description {
    display: grid;
    grid-template-columns: 30% auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "header header"
      "photo content";
    @media #{$break-smallMax} {
      margin-bottom: 60px;
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "header"
        "photo"
        "content";
    }

    &__title {
      grid-area: header;
      padding: 10px;
      color: white;
      background: #191919;
    }

    &__photo {
      grid-area: photo;
      border-radius: 50%;
      height: 150px;
      width: 150px;
      overflow: hidden;
      margin: 50px;
      background: wheat;
      place-self: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      line-height: 34px;
      font-size: 1.3em;
      grid-area: content;
      place-self: center;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;

    &__data,
    &__profile {
      min-height: 500px;
    }

    &__data {
      border-right: 1px solid #ccc;
      flex: 1 1 35%;

      @media #{$break-smallMax} {
        border: none;
      }
    }

    &__profile {
      flex: 2 1 65%;
    }
  }

  &__footer {
    padding: 10px;
    width: 45px;
    margin-left: auto;
    margin-top: 10px;

    .Logo {
      width: 30px;
    }
  }
}

.SumaryProfile,
.TechnicalData {
  min-height: 550px;
  &__title {
    padding: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  &__list {
    padding: 50px 10px;

    &__item {
      font-size: 1.3em;
      margin: 30px 0;
    }
  }
}

.SumaryProfile {
  &__title,
  &__list {
    padding-left: 50px;

    @media #{$break-smallMax} {
      padding-left: 10px;
    }
  }

  &__list__item {
    display: flex;
    flex-direction: column;

    &__title,
    &__site,
    &__year,
    &__description p {
      margin-bottom: 20px;
    }

    &__description p {
      line-height: 34px;
    }

    &__title {
      font-size: 1.4em;
    }

    &__site {
      font-weight: bold;
      color: $brand_color-2;
    }

    &__year {
      font-size: 0.9em;
      font-style: italic;
    }
  }
}

.TechnicalData {
  &__list {
    text-align: start;
  }
  &__list__item {
    &::before {
      content: "•";
      margin: 5px;
      color: $pp-color;
    }
  }
}
