@import "../../../common.scss";

.Anchor {
  @include headerText();
  @include showAnimation(20px);
  position: relative;
  margin-right: 35px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleY(0);
    transform-origin: 0 bottom;
    transition: transform 500ms ease;
    width: 100%;
    height: 100%;
    padding: 15px 10px;
    border-radius: 4px;
    background: rgba($brand-color_2, 0.1);
  }

  &:hover::before {
    transform: translate(-50%, -50%) scaleY(1);
  }

  &.active {
    color: $brand-color_2;
  }

  &--header {
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation-delay: #{$i * $time-delay};
      }
    }

    @media #{$break-smallMax} {
      margin: 0 0 60px 40px;
      font-size: 1.4em;
      font-weight: bolder;
      color: $sc-color;

      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation-delay: #{$i * $time-delay + 0.3};
        }
      }
    }
    @media #{$break-landscape} {
      margin-bottom: 15px;
    }
  }

  &--footer {
    margin: 6px 0;
    font-size: 0.8em;
    line-height: 24px;
    text-transform: unset;

    @for $i from 1 through 5 {
      &:nth-of-type(#{$i}) {
        animation-delay: calc(#{$i * $time-delay} + 1.6s);
      }
    }

    &::before {
      padding: 10px;
    }

    &__author {
      margin-left: 10px;
      transition: all $timing-transition linear;
      &:hover {
        color: $brand-color_2;
      }
      &::before {
        content: none;
      }
    }
  }

  &--resume {
    margin: 0;
    text-transform: none;
  }
}
